import "core-js/modules/es.json.stringify.js";
// 设置localStorage
export var setStorage = function setStorage(key, obj) {
  var json = JSON.stringify(obj);
  window.localStorage.setItem(key, json);
};

// 获取localStorage
export var getStorage = function getStorage(key) {
  var str = window.localStorage.getItem(key);
  if (!str) {
    return null;
  }
  return JSON.parse(str);
};

// 移除localStorage
export var removeStorage = function removeStorage(key) {
  window.localStorage.removeItem(key);
};

// 获取浏览器默认语言
export var getBrowserLang = function getBrowserLang() {
  var browserLang = navigator.language ? navigator.language : navigator.browserLanguage;
  var defaultBrowserLang = '';
  if (browserLang.toLowerCase() === 'cn' || browserLang.toLowerCase() === 'zh' || browserLang.toLowerCase() === 'zh-cn') {
    defaultBrowserLang = 'cn';
  } else {
    defaultBrowserLang = 'en';
  }
  return defaultBrowserLang;
};