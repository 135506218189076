import _objectSpread from "/Users/lucky.zhang/workspaces/workspace_go/src/routerman/web/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.join.js";
import Vue from "vue";
import VueI18n from "vue-i18n";

//element 国际化处理
import locale from "element-ui/lib/locale";
import elEn from "element-ui/lib/locale/lang/en";
import elCn from "element-ui/lib/locale/lang/zh-CN";
import { getStorage, getBrowserLang } from '@/utils/storage';
Vue.use(VueI18n);

// 获取当前语言（初始化时localStorage里没有存语言，默认为浏览器当前的语言）
var lang = getStorage('lang') || getBrowserLang();

//将刚刚locales创建的文件分别引入并输出成i18n需要的格式
function loadLocaleMessages() {
  //检测locales文件夹中有多少个语系
  var locales = require.context("./locales", true, /.json$/);
  var messages = {};
  locales.keys().forEach(function (file) {
    var keyArr = file.split('/');
    keyArr.shift();
    messages[keyArr.join('.').replace(/\.json$/g, '')] = locales(file);
  });
  return {
    cn: _objectSpread(_objectSpread({}, messages.cn), elCn),
    en: _objectSpread(_objectSpread({}, messages.en), elEn)
  };
}
var i18n = new VueI18n({
  locale: lang,
  fallbackLocale: 'en',
  messages: loadLocaleMessages(),
  silentFallbackWarn: true,
  silentTranslationWarn: true
});
locale.i18n(function (key, value) {
  return i18n.t(key, value);
});
export default i18n;