import service from '@/utils/request';

// @Tags Viewinfo
// @Summary 分页获取Viewinfo列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取Viewinfo列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /viewinfo/getViewinfoList [get]
export var getViewinfo = function getViewinfo(params) {
  return service({
    url: "/viewinfo/getViewinfoList",
    method: 'get',
    donNotShowLoading: true,
    params: params
  });
};

// @Tags Viewinfo
// @Summary 获取组成view的基础数据
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "获取组成view的基础数据"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /viewinfo/getViewMetadata [get]
export var getViewMetadata = function getViewMetadata(params) {
  return service({
    url: "/viewinfo/getViewMetadata",
    method: 'get',
    donNotShowLoading: true,
    params: params
  });
};