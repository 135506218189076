import { getStorage, setStorage, getBrowserLang } from '@/utils/storage';
export var language = {
  namespaced: true,
  state: {
    language: getStorage('lang') || getBrowserLang() // 项目初始化时，默认为浏览器的语言
  },

  getters: {
    language: function language(state) {
      return state.language;
    }
  },
  mutations: {
    setLanguage: function setLanguage(state, language) {
      state.language = language;
      setStorage('lang', language);
    }
  }
};