import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.push.js";
import axios from 'axios'; // 引入axios

import { store } from '@/store/index';
import context from '@/main.js';
import router from '@/router/index';
import i18n from '@/i18n.js';
var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 99999
});
var acitveAxios = 0;
var timer;
var showLoading = function showLoading() {
  acitveAxios++;
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(function () {
    if (acitveAxios > 0) {
      context.$bus.emit("showLoading");
    }
  }, 400);
};
var closeLoading = function closeLoading() {
  if (acitveAxios > 0) {
    acitveAxios--;
  }
  if (acitveAxios <= 0) {
    clearTimeout(timer);
    context.$bus.emit("closeLoading");
  }
};
//http request 拦截器
service.interceptors.request.use(function (config) {
  if (!config.donNotShowLoading) {
    showLoading();
  }
  var token = store.getters['user/token'];
  var user = store.getters['user/userInfo'];
  config.data = JSON.stringify(config.data);
  config.headers = {
    'Content-Type': 'application/json',
    'x-token': token,
    'x-user-id': user.ID,
    'X-Referer': 'router-man'
  };
  return config;
}, function (error) {
  closeLoading();
  _Message({
    showClose: true,
    message: error,
    type: 'error'
  });
  return error;
});

//http response 拦截器
service.interceptors.response.use(function (response) {
  closeLoading();
  if (response.headers["new-token"]) {
    store.commit('user/setToken', response.headers["new-token"]);
  }
  if (response.data.code == 0) {
    if (response.data.data.needInit) {
      _Message({
        type: "info",
        message: "This is your first time to use, please initialize"
      });
      store.commit("user/NeedInit");
      router.push({
        name: "init"
      });
    } else if (response.data.data.needModifyPwd != undefined) {
      store.commit('user/needModifyPwd', response.data.data.needModifyPwd);
    }
  }
  if (response.data.code == 0 || response.data.code == 40030 || response.data.code == 40031 || response.headers.success === "true") {
    return response.data;
  } else {
    var _msg = response.data.msg;
    if (i18n.te('login.' + response.data.msg)) {
      _msg = i18n.t('login.' + response.data.msg);
    }
    _Message({
      showClose: true,
      message: _msg || response.data.msg || decodeURI(response.headers.msg),
      type: response.headers.msgtype || 'error'
    });
    if (response.data.data && response.data.data.reload) {
      store.commit('user/LoginOut');
    }
    return response.data.msg ? response.data : response;
  }
}, function (error) {
  closeLoading();
  _Message({
    showClose: true,
    message: error,
    type: 'error'
  });
  return error;
});
export default service;