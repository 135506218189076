import service from '@/utils/request';

// @Tags CustomizeLines
// @Summary 创建CustomizeLines
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CustomizeLines true "创建CustomizeLines"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bizCustomizeLines/createCustomizeLines [post]
export var createCustomizeLines = function createCustomizeLines(data) {
  return service({
    url: "/clines",
    method: 'post',
    data: data
  });
};

// @Tags CustomizeLines
// @Summary 删除CustomizeLines
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CustomizeLines true "删除CustomizeLines"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /bizCustomizeLines/deleteCustomizeLines [delete]
export var deleteCustomizeLines = function deleteCustomizeLines(data) {
  return service({
    url: "/clines/" + data.id + '?zone=' + data.zone,
    method: 'delete'
  });
};

// @Tags CustomizeLines
// @Summary 删除CustomizeLines
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除CustomizeLines"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /bizCustomizeLines/deleteCustomizeLines [delete]
export var deleteCustomizeLinesByIds = function deleteCustomizeLinesByIds(data) {
  return service({
    url: "/clines/deleteCustomizeLinesByIds",
    method: 'delete',
    data: data
  });
};

// @Tags CustomizeLines
// @Summary 更新CustomizeLines
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CustomizeLines true "更新CustomizeLines"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /bizCustomizeLines/updateCustomizeLines [put]
export var updateCustomizeLines = function updateCustomizeLines(data) {
  return service({
    url: "/clines/" + data.id,
    method: 'put',
    data: data
  });
};

// @Tags CustomizeLines
// @Summary 用id查询CustomizeLines
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.CustomizeLines true "用id查询CustomizeLines"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /bizCustomizeLines/findCustomizeLines [get]
export var findCustomizeLines = function findCustomizeLines(params) {
  return service({
    url: "/clines/" + params.id,
    method: 'get',
    params: params
  });
};

// @Tags CustomizeLines
// @Summary 分页获取CustomizeLines列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取CustomizeLines列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /bizCustomizeLines/getCustomizeLinesList [get]
export var getCustomizeLinesList = function getCustomizeLinesList(params) {
  return service({
    url: "/clines",
    method: 'get',
    donNotShowLoading: true,
    params: params
  });
};