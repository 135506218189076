import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.error.cause.js";
// 权限按钮展示指令 v-auth="['url@GET']"
import { store } from '@/store/index';
// const permissions = store.getters['api/authAPI']

export var auth = function auth(Vue) {
  Vue.directive('auth', {
    // 当被绑定的元素插入到 DOM 中时……
    bind: function bind(el, binding) {
      var value = binding.value;
      var permissions = store.getters && store.getters['router/routerList'];
      console.log(permissions);
      if (value && value instanceof Array && value.length > 0) {
        var permissionFlag = value;

        // console.log(permissions)
        // console.log(permissionFlag)

        var hasPermissions = permissions.some(function (permission) {
          return permissionFlag.includes(permission.value);
        });

        // console.log(hasPermissions)

        if (!hasPermissions) {
          // el.style.display = 'none'
          // el.parentNode && el.parentNode.removeChild(el)
          el.classList.add('is-hide');
          el.setAttribute('hidden', true);
        }
      } else {
        throw new Error("\u8BF7\u8BBE\u7F6E\u64CD\u4F5C\u6743\u9650\u6807\u7B7E\u503C");
      }
    }
  });
};