import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);

//获取原型对象上的push函数
var originalPush = Router.prototype.push;
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};
var baseRouters = [{
  path: '/',
  redirect: '/login'
}, {
  path: "/init",
  name: 'init',
  component: function component() {
    return import('@/view/init/init.vue');
  }
}, {
  path: "/modify_pwd",
  name: 'modifyPwd',
  component: function component() {
    return import('@/view/person/modifyPwd.vue');
  }
}, {
  path: '/login',
  name: 'login',
  component: function component() {
    return import('@/view/login/login.vue');
  }
}, {
  path: "/emailConfirm",
  name: 'emailConfirm',
  component: function component() {
    return import('@/view/superAdmin/user/emailConfirm.vue');
  }
}];

// 定义一个resetRouter 方法
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher;
}

// 需要通过后台数据来生成的组件

var createRouter = function createRouter() {
  return new Router({
    routes: baseRouters
  });
};
var router = createRouter();
export default router;