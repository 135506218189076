import service from '@/utils/request';

// @Summary 用户登录
// @Produce  application/json
// @Param data body {username:"string",password:"string"}
// @Router /base/login [post]
export var login = function login(data) {
  return service({
    url: "/base/login",
    method: 'post',
    data: data
  });
};

// @Summary 获取验证码
// @Produce  application/json
// @Param data body {username:"string",password:"string"}
// @Router /base/captcha [post]
export var captcha = function captcha(data) {
  return service({
    url: "/base/captcha",
    method: 'post',
    data: data,
    donNotShowLoading: true
  });
};

// @Summary 用户注册
// @Produce  application/json
// @Param data body {username:"string",password:"string"}
// @Router /base/resige [post]
export var register = function register(data) {
  return service({
    url: "/user/register",
    method: 'post',
    data: data
  });
};
// @Summary 修改密码
// @Produce  application/json
// @Param data body {username:"string",password:"string",newPassword:"string"}
// @Router /user/changePassword [post]
export var changePassword = function changePassword(data) {
  return service({
    url: "/user/changePassword",
    method: 'put',
    data: data
  });
};
// @Tags User
// @Summary 分页获取用户列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body modelInterface.PageInfo true "分页获取用户列表"
// @Success 200 {string} json "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /user/getUserList [post]
export var getUserList = function getUserList(params) {
  return service({
    url: "/user/getUserList",
    method: 'get',
    params: params
  });
};

// @Tags User
// @Summary 设置用户权限
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body api.SetUserAuth true "设置用户权限"
// @Success 200 {string} json "{"success":true,"data":{},"msg":"修改成功"}"
// @Router /user/setUserAuthority [post]
export var setUserAuthority = function setUserAuthority(data) {
  return service({
    url: "/user/setUserAuthority",
    method: 'put',
    data: data
  });
};

// @Tags SysUser
// @Summary 删除用户
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.SetUserAuth true "删除用户"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"修改成功"}"
// @Router /user/deleteUser [delete]
export var deleteUser = function deleteUser(data) {
  return service({
    url: "/user/deleteUser",
    method: 'delete',
    data: data
  });
};

// @Tags SysUser
// @Summary 设置用户信息
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysUser true "设置用户信息"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"修改成功"}"
// @Router /user/setUserInfo [put]
export var setUserInfo = function setUserInfo(data) {
  return service({
    url: "/user/setUserInfo",
    method: 'put',
    data: data
  });
};

// @Tags User
// @Summary 分页获取用户列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body modelInterface.PageInfo true "分页获取用户列表"
// @Success 200 {string} json "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /user/getUserList [post]
export var listUser = function listUser(data) {
  return service({
    url: "/user/listUser",
    method: 'get',
    data: data
  });
};

// @Tags SysUser
// @Summary 修改用户Team
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.SysUser true "修改用户Team"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"修改成功"}"
// @Router /user/changeUserTeam [put]
export var changeUserTeam = function changeUserTeam(data) {
  return service({
    url: "/user/changeUserTeam",
    method: 'put',
    data: data
  });
};

// @Summary 修改密码
// @Produce  application/json
// @Param data body {username:"string",password:"string",newPassword:"string"}
// @Router /user/changePassword [post]
export var resetPassword = function resetPassword(data) {
  return service({
    url: "/user/resetPassword",
    method: 'put',
    data: data
  });
};

// @Summary 激活用户
// @Produce  application/json
// @Param data body {username:"string",password:"string",newPassword:"string"}
// @Router /user/activeAccount [put]
export var activeAccount = function activeAccount(data) {
  return service({
    url: "/base/activeAccount",
    method: 'put',
    data: data
  });
};