import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.number.constructor.js";
/* eslint-disable */
export var toUpperCase = function toUpperCase(str) {
  if (str[0]) {
    return str.replace(str[0], str[0].toUpperCase());
  } else {
    return "";
  }
};
export var toLowerCase = function toLowerCase(str) {
  if (str[0]) {
    return str.replace(str[0], str[0].toLowerCase());
  } else {
    return "";
  }
};

// 驼峰转换下划线
export var toSQLLine = function toSQLLine(str) {
  if (str == "ID") return "ID";
  return str.replace(/([A-Z])/g, "_$1").toLowerCase();
};

// 下划线转换驼峰
export var toHump = function toHump(name) {
  return name.replace(/\_(\w)/g, function (all, letter) {
    return letter.toUpperCase();
  });
};
export var ipToInt = function ipToInt(ip) {
  var REG = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
  var result = REG.exec(ip);
  if (!result) return -1;
  return (parseInt(result[1]) << 24 | parseInt(result[2]) << 16 | parseInt(result[3]) << 8 | parseInt(result[4])) >>> 0;
};
export var intToIp = function intToIp(INT) {
  if (INT < 0 || INT > 0xFFFFFFFF) {
    throw "The number is not normal!";
  }
  return (INT >>> 24) + "." + (INT >> 16 & 0xFF) + "." + (INT >> 8 & 0xFF) + "." + (INT & 0xFF);
};

// 验证一般域名校验
export var checkDomain = function checkDomain(domain) {
  var re = /^(?=^.{3,255}$)[a-zA-Z0-9_][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9_][-a-zA-Z0-9]{0,62})+$/g;
  return domain.match(re);
};

// 验证是否为IPv4
export var isIPv4 = function isIPv4(ip) {
  var re = /^((?:(?:25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(?:25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d))))$/g; // 匹配IP地址的正则表达式
  return ip.match(re);
};

// 验证是否为IPv6
export var isIPv6 = function isIPv6(ip) {
  var re = /^(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))$/;
  return ip.match(re);
};
export var compare = function compare(prop, orderType) {
  return function (obj1, obj2) {
    var val1 = obj1[prop];
    var val2 = obj2[prop];
    if (!isNaN(Number(val1)) && !isNaN(Number(val2))) {
      val1 = Number(val1);
      val2 = Number(val2);
    }
    var cmp = 0;
    if (val1 < val2) {
      cmp = -1;
    } else if (val1 > val2) {
      cmp = 1;
    }
    return orderType == 1 ? cmp : -cmp;
  };
};
export var randomString = function randomString(length) {
  var str = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = '';
  for (var i = length; i > 0; --i) result += str[Math.floor(Math.random() * str.length)];
  return result;
};