import service from '@/utils/request';

// @Tags HcTpl
// @Summary 创建HcTpl
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.HcTpl true "创建HcTpl"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /hc_tpl/createHcTpl [post]
export var createHcTpl = function createHcTpl(data) {
  return service({
    url: "/hc_tpl/createHcTpl",
    method: 'post',
    data: data
  });
};

// @Tags HcTpl
// @Summary 删除HcTpl
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.HcTpl true "删除HcTpl"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /hc_tpl/deleteHcTpl [delete]
export var deleteHcTpl = function deleteHcTpl(data) {
  return service({
    url: "/hc_tpl/deleteHcTpl",
    method: 'delete',
    data: data
  });
};

// @Tags HcTpl
// @Summary 删除HcTpl
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.IdsReq true "批量删除HcTpl"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"删除成功"}"
// @Router /hc_tpl/deleteHcTpl [delete]
export var deleteHcTplByIds = function deleteHcTplByIds(data) {
  return service({
    url: "/hc_tpl/deleteHcTplByIds",
    method: 'delete',
    data: data
  });
};

// @Tags HcTpl
// @Summary 更新HcTpl
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.HcTpl true "更新HcTpl"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"更新成功"}"
// @Router /hc_tpl/updateHcTpl [put]
export var updateHcTpl = function updateHcTpl(data) {
  return service({
    url: "/hc_tpl/updateHcTpl",
    method: 'put',
    data: data
  });
};

// @Tags HcTpl
// @Summary 用id查询HcTpl
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body model.HcTpl true "用id查询HcTpl"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"查询成功"}"
// @Router /hc_tpl/findHcTpl [get]
export var findHcTpl = function findHcTpl(params) {
  return service({
    url: "/hc_tpl/findHcTpl",
    method: 'get',
    params: params
  });
};

// @Tags HcTpl
// @Summary 分页获取HcTpl列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "分页获取HcTpl列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /hc_tpl/getHcTplList [get]
export var getHcTplList = function getHcTplList(params) {
  return service({
    url: "/hc_tpl/getHcTplList",
    method: 'get',
    donNotShowLoading: false,
    params: params
  });
};

// @Tags HcTpl
// @Summary 获取HcTpl列表
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body request.PageInfo true "获取HcTpl列表"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /hc_tpl/getHcTplListByTeam [get]
export var getHcTplListByTeam = function getHcTplListByTeam(params) {
  return service({
    url: "/hc_tpl/getHcTplListByTeam",
    method: 'get',
    donNotShowLoading: true,
    params: params
  });
};

// @Tags HcTpl
// @Summary 获取HcTpl数量
// @Security ApiKeyAuth
// @accept application/json
// @Produce application/json
// @Param data body int true "获取HcTpl数量"
// @Success 200 {string} string "{"success":true,"data":{},"msg":"获取成功"}"
// @Router /hc_tpl/getHcTplCountByTeam [get]
export var getHcTplCountByTeam = function getHcTplCountByTeam(params) {
  return service({
    url: "/hc_tpl/getHcTplCountByTeam",
    method: 'get',
    donNotShowLoading: true,
    params: params
  });
};