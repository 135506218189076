import "core-js/modules/es.array.concat.js";
var install = function install(Vue) {
  var Bus = new Vue({
    methods: {
      emit: function emit(event) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }
        this.$emit.apply(this, [event].concat(args));
      },
      on: function on(event, cb) {
        this.$on(event, cb);
      },
      off: function off(event, cb) {
        this.$off(event, cb);
      }
    }
  });
  Vue.prototype.$bus = Bus;
};
export default install;