import _MessageBox2 from "element-ui/lib/theme-chalk/message-box.css";
import "element-ui/lib/theme-chalk/base.css";
import _MessageBox from "element-ui/lib/message-box";
import _Message2 from "element-ui/lib/theme-chalk/message.css";
import "element-ui/lib/theme-chalk/base.css";
import _Message from "element-ui/lib/message";
import _Result2 from "element-ui/lib/theme-chalk/result.css";
import "element-ui/lib/theme-chalk/base.css";
import _Result from "element-ui/lib/result";
import _DescriptionsItem2 from "element-ui/lib/theme-chalk/descriptions-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _DescriptionsItem from "element-ui/lib/descriptions-item";
import _Descriptions2 from "element-ui/lib/theme-chalk/descriptions.css";
import "element-ui/lib/theme-chalk/base.css";
import _Descriptions from "element-ui/lib/descriptions";
import _Alert2 from "element-ui/lib/theme-chalk/alert.css";
import "element-ui/lib/theme-chalk/base.css";
import _Alert from "element-ui/lib/alert";
import _Backtop2 from "element-ui/lib/theme-chalk/backtop.css";
import "element-ui/lib/theme-chalk/base.css";
import _Backtop from "element-ui/lib/backtop";
import _Link2 from "element-ui/lib/theme-chalk/link.css";
import "element-ui/lib/theme-chalk/base.css";
import _Link from "element-ui/lib/link";
import _TimelineItem2 from "element-ui/lib/theme-chalk/timeline-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _TimelineItem from "element-ui/lib/timeline-item";
import _Timeline2 from "element-ui/lib/theme-chalk/timeline.css";
import "element-ui/lib/theme-chalk/base.css";
import _Timeline from "element-ui/lib/timeline";
import _CascaderPanel2 from "element-ui/lib/theme-chalk/cascader-panel.css";
import "element-ui/lib/theme-chalk/base.css";
import _CascaderPanel from "element-ui/lib/cascader-panel";
import _PageHeader2 from "element-ui/lib/theme-chalk/page-header.css";
import "element-ui/lib/theme-chalk/base.css";
import _PageHeader from "element-ui/lib/page-header";
import _Loading2 from "element-ui/lib/theme-chalk/loading.css";
import "element-ui/lib/theme-chalk/base.css";
import _Loading from "element-ui/lib/loading";
import _Scrollbar2 from "element-ui/lib/theme-chalk/scrollbar.css";
import "element-ui/lib/theme-chalk/base.css";
import _Scrollbar from "element-ui/lib/scrollbar";
import _Progress2 from "element-ui/lib/theme-chalk/progress.css";
import "element-ui/lib/theme-chalk/base.css";
import _Progress from "element-ui/lib/progress";
import _Upload2 from "element-ui/lib/theme-chalk/upload.css";
import "element-ui/lib/theme-chalk/base.css";
import _Upload from "element-ui/lib/upload";
import _Steps2 from "element-ui/lib/theme-chalk/steps.css";
import "element-ui/lib/theme-chalk/base.css";
import _Steps from "element-ui/lib/steps";
import _DatePicker2 from "element-ui/lib/theme-chalk/date-picker.css";
import "element-ui/lib/theme-chalk/base.css";
import _DatePicker from "element-ui/lib/date-picker";
import _Tooltip2 from "element-ui/lib/theme-chalk/tooltip.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tooltip from "element-ui/lib/tooltip";
import _CollapseItem2 from "element-ui/lib/theme-chalk/collapse-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _CollapseItem from "element-ui/lib/collapse-item";
import _Collapse2 from "element-ui/lib/theme-chalk/collapse.css";
import "element-ui/lib/theme-chalk/base.css";
import _Collapse from "element-ui/lib/collapse";
import _Switch2 from "element-ui/lib/theme-chalk/switch.css";
import "element-ui/lib/theme-chalk/base.css";
import _Switch from "element-ui/lib/switch";
import _InputNumber2 from "element-ui/lib/theme-chalk/input-number.css";
import "element-ui/lib/theme-chalk/base.css";
import _InputNumber from "element-ui/lib/input-number";
import _Popover2 from "element-ui/lib/theme-chalk/popover.css";
import "element-ui/lib/theme-chalk/base.css";
import _Popover from "element-ui/lib/popover";
import _CheckboxGroup2 from "element-ui/lib/theme-chalk/checkbox-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _CheckboxGroup from "element-ui/lib/checkbox-group";
import _Tree2 from "element-ui/lib/theme-chalk/tree.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tree from "element-ui/lib/tree";
import _Drawer2 from "element-ui/lib/theme-chalk/drawer.css";
import "element-ui/lib/theme-chalk/base.css";
import _Drawer from "element-ui/lib/drawer";
import _Pagination2 from "element-ui/lib/theme-chalk/pagination.css";
import "element-ui/lib/theme-chalk/base.css";
import _Pagination from "element-ui/lib/pagination";
import _Tag2 from "element-ui/lib/theme-chalk/tag.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tag from "element-ui/lib/tag";
import _Cascader2 from "element-ui/lib/theme-chalk/cascader.css";
import "element-ui/lib/theme-chalk/base.css";
import _Cascader from "element-ui/lib/cascader";
import _Checkbox2 from "element-ui/lib/theme-chalk/checkbox.css";
import "element-ui/lib/theme-chalk/base.css";
import _Checkbox from "element-ui/lib/checkbox";
import _TableColumn2 from "element-ui/lib/theme-chalk/table-column.css";
import "element-ui/lib/theme-chalk/base.css";
import _TableColumn from "element-ui/lib/table-column";
import _Table2 from "element-ui/lib/theme-chalk/table.css";
import "element-ui/lib/theme-chalk/base.css";
import _Table from "element-ui/lib/table";
import _Divider2 from "element-ui/lib/theme-chalk/divider.css";
import "element-ui/lib/theme-chalk/base.css";
import _Divider from "element-ui/lib/divider";
import _TabPane2 from "element-ui/lib/theme-chalk/tab-pane.css";
import "element-ui/lib/theme-chalk/base.css";
import _TabPane from "element-ui/lib/tab-pane";
import _Avatar2 from "element-ui/lib/theme-chalk/avatar.css";
import "element-ui/lib/theme-chalk/base.css";
import _Avatar from "element-ui/lib/avatar";
import _BreadcrumbItem2 from "element-ui/lib/theme-chalk/breadcrumb-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _BreadcrumbItem from "element-ui/lib/breadcrumb-item";
import _Breadcrumb2 from "element-ui/lib/theme-chalk/breadcrumb.css";
import "element-ui/lib/theme-chalk/base.css";
import _Breadcrumb from "element-ui/lib/breadcrumb";
import _Tabs2 from "element-ui/lib/theme-chalk/tabs.css";
import "element-ui/lib/theme-chalk/base.css";
import _Tabs from "element-ui/lib/tabs";
import _Header2 from "element-ui/lib/theme-chalk/header.css";
import "element-ui/lib/theme-chalk/base.css";
import _Header from "element-ui/lib/header";
import _Badge2 from "element-ui/lib/theme-chalk/badge.css";
import "element-ui/lib/theme-chalk/base.css";
import _Badge from "element-ui/lib/badge";
import _Main2 from "element-ui/lib/theme-chalk/main.css";
import "element-ui/lib/theme-chalk/base.css";
import _Main from "element-ui/lib/main";
import _Aside2 from "element-ui/lib/theme-chalk/aside.css";
import "element-ui/lib/theme-chalk/base.css";
import _Aside from "element-ui/lib/aside";
import _MenuItem2 from "element-ui/lib/theme-chalk/menu-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _MenuItem from "element-ui/lib/menu-item";
import _Submenu2 from "element-ui/lib/theme-chalk/submenu.css";
import "element-ui/lib/theme-chalk/base.css";
import _Submenu from "element-ui/lib/submenu";
import _Menu2 from "element-ui/lib/theme-chalk/menu.css";
import "element-ui/lib/theme-chalk/base.css";
import _Menu from "element-ui/lib/menu";
import _Col2 from "element-ui/lib/theme-chalk/col.css";
import "element-ui/lib/theme-chalk/base.css";
import _Col from "element-ui/lib/col";
import _Row2 from "element-ui/lib/theme-chalk/row.css";
import "element-ui/lib/theme-chalk/base.css";
import _Row from "element-ui/lib/row";
import _DropdownItem2 from "element-ui/lib/theme-chalk/dropdown-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _DropdownItem from "element-ui/lib/dropdown-item";
import _DropdownMenu2 from "element-ui/lib/theme-chalk/dropdown-menu.css";
import "element-ui/lib/theme-chalk/base.css";
import _DropdownMenu from "element-ui/lib/dropdown-menu";
import _Dropdown2 from "element-ui/lib/theme-chalk/dropdown.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dropdown from "element-ui/lib/dropdown";
import _Card2 from "element-ui/lib/theme-chalk/card.css";
import "element-ui/lib/theme-chalk/base.css";
import _Card from "element-ui/lib/card";
import _Container2 from "element-ui/lib/theme-chalk/container.css";
import "element-ui/lib/theme-chalk/base.css";
import _Container from "element-ui/lib/container";
import _Option2 from "element-ui/lib/theme-chalk/option.css";
import "element-ui/lib/theme-chalk/base.css";
import _Option from "element-ui/lib/option";
import _Input2 from "element-ui/lib/theme-chalk/input.css";
import "element-ui/lib/theme-chalk/base.css";
import _Input from "element-ui/lib/input";
import _FormItem2 from "element-ui/lib/theme-chalk/form-item.css";
import "element-ui/lib/theme-chalk/base.css";
import _FormItem from "element-ui/lib/form-item";
import _Form2 from "element-ui/lib/theme-chalk/form.css";
import "element-ui/lib/theme-chalk/base.css";
import _Form from "element-ui/lib/form";
import _Dialog2 from "element-ui/lib/theme-chalk/dialog.css";
import "element-ui/lib/theme-chalk/base.css";
import _Dialog from "element-ui/lib/dialog";
import _Select2 from "element-ui/lib/theme-chalk/select.css";
import "element-ui/lib/theme-chalk/base.css";
import _Select from "element-ui/lib/select";
import _ButtonGroup2 from "element-ui/lib/theme-chalk/button-group.css";
import "element-ui/lib/theme-chalk/base.css";
import _ButtonGroup from "element-ui/lib/button-group";
import _Button2 from "element-ui/lib/theme-chalk/button.css";
import "element-ui/lib/theme-chalk/base.css";
import _Button from "element-ui/lib/button";
import "/Users/lucky.zhang/workspaces/workspace_go/src/routerman/web/node_modules/core-js/modules/es.array.iterator.js";
import "/Users/lucky.zhang/workspaces/workspace_go/src/routerman/web/node_modules/core-js/modules/es.promise.js";
import "/Users/lucky.zhang/workspaces/workspace_go/src/routerman/web/node_modules/core-js/modules/es.object.assign.js";
import "/Users/lucky.zhang/workspaces/workspace_go/src/routerman/web/node_modules/core-js/modules/es.promise.finally.js";
import Vue from 'vue';
import App from './App.vue';
import i18n from "@/i18n";

//  按需引入element

Vue.use(_Button);
Vue.use(_ButtonGroup);
Vue.use(_Select);
Vue.use(_Dialog);
Vue.use(_Form);
Vue.use(_FormItem);
Vue.use(_Input);
Vue.use(_Option);
Vue.use(_Container);
Vue.use(_Card);
Vue.use(_Dropdown);
Vue.use(_DropdownMenu);
Vue.use(_DropdownItem);
Vue.use(_Row);
Vue.use(_Col);
Vue.use(_Menu);
Vue.use(_Submenu);
Vue.use(_MenuItem);
Vue.use(_Aside);
Vue.use(_Main);
Vue.use(_Badge);
Vue.use(_Header);
Vue.use(_Tabs);
Vue.use(_Breadcrumb);
Vue.use(_BreadcrumbItem);
Vue.use(_Avatar);
Vue.use(_TabPane);
Vue.use(_Divider);
Vue.use(_Table);
Vue.use(_TableColumn);
Vue.use(_Checkbox);
Vue.use(_Cascader);
Vue.use(_Tag);
Vue.use(_Pagination);
Vue.use(_Drawer);
Vue.use(_Tree);
Vue.use(_CheckboxGroup);
Vue.use(_Popover);
Vue.use(_InputNumber);
Vue.use(_Switch);
Vue.use(_Collapse);
Vue.use(_CollapseItem);
Vue.use(_Tooltip);
Vue.use(_DatePicker);
Vue.use(_Steps);
Vue.use(_Upload);
Vue.use(_Progress);
Vue.use(_Scrollbar);
Vue.use(_Loading.directive);
Vue.use(_PageHeader);
Vue.use(_CascaderPanel);
Vue.use(_Timeline);
Vue.use(_TimelineItem);
Vue.use(_Link);
Vue.use(_Backtop);
Vue.use(_Alert);
Vue.use(_Descriptions);
Vue.use(_DescriptionsItem);
Vue.use(_Result);
Vue.prototype.$loading = _Loading.service;
Vue.prototype.$message = _Message;
Vue.prototype.$confirm = _MessageBox.confirm;
Vue.prototype.$prompt = _MessageBox.prompt;
_Dialog.props.closeOnClickModal.default = false;

// 引入封装的router
import router from '@/router/index';

// time line css
import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css';
// font
import '@/style/style.css';
import '@/permission';
import { store } from '@/store/index';
Vue.config.productionTip = false;

// 路由守卫
import Bus from '@/utils/bus.js';
Vue.use(Bus);
import APlayer from '@moefe/vue-aplayer';
Vue.use(APlayer, {
  defaultCover: 'https://github.com/u3u.png',
  productionTip: true
});
import { auth } from '@/directive/auth';
import { sec } from '@/directive/sec';
// 按钮权限指令
auth(Vue);
sec(Vue);
Vue.directive('focus', {
  inserted: function inserted(el) {
    // 聚焦元素
    el.querySelector('input').focus();
  }
});
import uploader from 'vue-simple-uploader';
Vue.use(uploader);

// 复制
import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

// highcharts
import HighchartsVue from "highcharts-vue";
Vue.use(HighchartsVue);
export default new Vue({
  i18n: i18n,
  render: function render(h) {
    return h(App);
  },
  router: router,
  store: store
}).$mount('#app');

//引入echarts
// import * as Echarts from 'echarts'
import * as echarts from 'echarts/core';
import { TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, VisualMapComponent, GeoComponent } from 'echarts/components';
import { LineChart, MapChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
echarts.use([TitleComponent, ToolboxComponent, TooltipComponent, GridComponent, VisualMapComponent, GeoComponent, LineChart, MapChart, CanvasRenderer]);
Vue.prototype.$echarts = echarts;
console.log("\n       \u9ED8\u8BA4\u81EA\u52A8\u5316\u6587\u6863\u5730\u5740:http://127.0.0.1%s/swagger/index.html\n       \u9ED8\u8BA4\u524D\u7AEF\u6587\u4EF6\u8FD0\u884C\u5730\u5740:http://127.0.0.1:8080\n");