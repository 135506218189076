import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { user } from "@/store/module/user";
import { router } from "@/store/module/router";
import { dictionary } from "@/store/module/dictionary";
import { viewinfo } from "@/store/module/viewinfo";
import { api } from "@/store/module/api";
import { language } from "./module/language";
Vue.use(Vuex);
var vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['user', 'api']
});
export var store = new Vuex.Store({
  modules: {
    user: user,
    router: router,
    dictionary: dictionary,
    viewinfo: viewinfo,
    api: api,
    language: language
  },
  plugins: [vuexLocal.plugin]
});